<template>
  <footer class="footer extendedBg">
    <div class="footer__top">
      <nuxt-link
        class="footer__logo"
        :to="localePath({name: 'index'})"
      >
        <img :src="partnerStore.cashtecLogo" class="icon icon--cashtec" alt="CashTec logo">
      </nuxt-link>
      <div class="footer__links">
        <nuxt-link v-if="!authStore.isLoggedIn && !partnerStore.getBuyoutPartner" :to="localePath( {name: 'premium'})">
          {{ t("form.login.normal_partner") }}
        </nuxt-link>
        <nuxt-link :to="localePath({name: 'index'})">
          {{ t("form.common.about_us") }}
        </nuxt-link>
        <nuxt-link v-if="!authStore.isLoggedIn && !partnerStore.getBuyoutPartner" to="https://www.mp.cz">
          Mobil Pohotovost
        </nuxt-link>
      </div>
    </div>
    <div class="footer__divider" />
    <div class="footer__bottom">
      <div class="footer__copyright">
        Copyright © {{ currentYear }} Mobil Pohotovost GSM s.r.o.
      </div>
      <nuxt-link :to="localePath({name: 'privacy-policy'})">
        {{ t("form.common.privacy_policy") }}
      </nuxt-link>
      <nuxt-link :to="localePath({name: 'terms-and-conditions'})">
        {{ t("form.common.terms_of_use") }}
      </nuxt-link>
    </div>
  </footer>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
const authStore = useAuthStore();
const partnerStore = usePartnerStore();
const { t } = useI18n();

const currentYear = new Date().getFullYear();
</script>
